@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container{
  display: flex; 
   justify-content: center;
    align-items: center;
    width: 30%;
    padding: 3% 0 0 30%;
   
}
 @media screen and (max-width: 719px) {
 .container{ 
  width: 60%;
padding: 3% 0 0 20%;}
}
@media screen and (max-width: 319px) {
 .container{ 
  width: 80%;
padding: 3% 10%;}
}

.img-logo{
  width: 30%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.img{
  margin: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
   width: 30%;
  height: 20%;
}

.texts{
  margin-bottom: 20px;
}
.login_button{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ads__holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  gap: 30px;
}

.ads__card {
  background-color: rgba(244, 151, 3, 0.8);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
}
.page-header {
  margin-bottom: 40px !important;
  text-transform: capitalize;
}

.Button {
  width: 100px;
  padding: 5px;
  background-color: var(--primary-main-color) !important;
  border-radius: 5px;
  cursor: pointer;
  color: var(--txt-white) !important;
  font-size: 16px;
  border: 1px solid var(--primary-main-color) !important;
  white-space: nowrap;
}
.Button:hover {
  color: var(--primary-main-color) !important;
  background-color: var(--main-bg) !important;
}

.card {
  padding: 30px;
  margin: 0px 0px 30px 0px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}
.image__card {
  border: 3px solid rgba(244, 151, 3, 0.8);
  background: rgba(244, 151, 3, 0.8);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 10px;
}
.ad__delete__icon {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
}
.ad__image {
  width: 100% !important;
  height: 100% !important;
}
@media only screen and (max-width: 918px) {
  .ads__holder {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }
}

.front {
  /* background-image: url("../images/ad.svg"); */

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white !important;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* border: 4px solid black */
}

.card_title {
  padding-top: 30px;
  color: #fff;
  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 25px;
}

.card_subline {
  padding-top: 205px;
  color: rgb(20, 61, 89);
  font-size: 1.5em;
  line-height: 25px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.card_headline {
  line-height: 23px;
}

.card_arrow {
  transition: all 0.35s linear;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: rgb(20, 61, 89);
  transition: all 0.2s linear;
  position: absolute;
  right: 40px;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  z-index: 2;
}
.card_arrow:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
body {
  margin: 1rem;
}

